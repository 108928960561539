<template>
  <div class="row" ref="table">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Tracking Logs</h4>
          <div class="commands">

            <l-button
              v-if="$store.getters['auth/checkAccess']('tracking-jobs/run-ups')"
              @click="runJob('ups')"
              :disabled="disableAction"
              type="info">
              Sync data with UPS
            </l-button>&nbsp;

            <l-button
              v-if="$store.getters['auth/checkAccess']('tracking-jobs/run-usps')"
              @click="runJob('usps')"
              :disabled="disableAction"
              type="success">
              Sync data with USPS
            </l-button>
          </div>

        </div>

        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="paginationData.pageSize"
              @change="getData('changePageSize')"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in paginationData.perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <el-input type="search"
                      class="mb-3"
                      style="width: 200px"
                      placeholder="Search records"
                      v-model="searchQuery"
                      @change="getData('updateSearch')"
                      aria-controls="datatables"/>
          </div>

          <div class="col-sm-12">
            <el-table stripe
                      style="width: 100%;"
                      :data="tableData"
                      border>

              <el-table-column v-for="column in tableColumns"
                               :key="column.label"
                               :min-width="column.minWidth"
                               align="center"
                               :prop="column.prop"
                               :label="column.label"
                               :formatter="column.formatter !== null ? column.formatter : null">
                <template slot-scope="scope">

                  <div v-if="column.prop === 'is_running'">

                  </div>

                  <el-tag v-if="column.prop === 'is_running'"
                          :type="getStatusColor(scope.row[column.prop])"
                          size="medium">
                    {{ getStatusValue(scope.row[column.prop]) }}
                  </el-tag>

                  <span v-else>{{ scope.row[column.prop] }}</span>
                </template>
              </el-table-column>


            </el-table>
          </div>


        </div>

        <div slot="footer">
          <NLPagination class="pagination-no-border"
                        v-model="paginationData.currentPage"
                        @input="getData('changePageNumber')"
                        :per-page="paginationData.pageSize"
                        :total="paginationData.totalItems">
          </NLPagination>
        </div>

      </card>


    </div>

  </div>
</template>
<script>
import {Table, TableColumn, Select, Option, Tag} from 'element-ui'
import {NewPagination as NLPagination} from 'src/components/index'

export default {
  components: {
    NLPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [Tag.name]: Tag,
    [TableColumn.name]: TableColumn
  },

  data() {

    return {
      loader: {},
      paginationData: {
        pageSize: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        totalItems: 0
      },


      searchQuery: '',
      disableAction: false,

      tableColumns: [
        {
          prop: 'job_name',
          label: 'Job Name',
          minWidth: 150
        },
        {
          prop: 'is_running',
          label: 'Status',
          minWidth: 200
        },
        {
          prop: 'start_time',
          label: 'Start',
          minWidth: 200
        },
        {
          prop: 'end_time',
          label: 'End',
          minWidth: 200
        },
        {
          prop: 'execution_time',
          label: 'Time (minutes)',
          minWidth: 200
        },
        {
          prop: 'total_items_number',
          label: 'Total Number Of Items',
          minWidth: 200
        },
        {
          prop: 'updated_items_number',
          label: 'Updated Number Of Items',
          minWidth: 200
        },
      ],

      tableData: []
    }

  },

  methods: {

    getData(action) {
      switch (action) {
        case 'updateSearch':
          this.updateCurrentPage(1);
          break;
        case 'changePageSize':
          this.updateCurrentPage(1);
          break;
        case 'updateData':
          this.searchQuery = '';
          this.updateCurrentPage(1);
          break;
        case 'changePageNumber' :
          break;
      }

      this.loader = this.$loading.show({
        container: this.$refs.table
      });

      this.axios.get("tracking-jobs/get-logs?page=" + this.paginationData.currentPage +
        "&pageSize=" + this.paginationData.pageSize +
        "&searchQuery=" + this.searchQuery).then((response) => {
        this.tableData = response.data.data;
        this.paginationData.totalItems = response.data.pagination.total;
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
      });
    },

    updateCurrentPage(pageNumber) {
      this.paginationData.currentPage = pageNumber;
    },

    getStatusValue(status) {
      let values = {
        "1": "Is Running",
        "0": "Done"
      }
      return values[status];
    },
    getStatusColor(status) {
      let classes = {
        "1": "danger",
        "0": "success"
      }
      return classes[status];
    },

    runJob(type) {
      this.disableAction = true;
      let url = "";
      if (type === "ups") url = "tracking-jobs/run-ups";
      else url = "tracking-jobs/run-usps"

      this.axios.post(url).then((response) => {

        this.$notify({
          message: "job started successfully",
          timeout: 1000,
          type: 'success'
        });

        this.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }).finally(() => {
        this.disableAction = false;
      })

    }

  },

  mounted() {
    this.getData();
  },


}
</script>
<style>
</style>

<template>

  <div class="row" ref="imagesForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">

              <div class="col-xs-12 col-md-12 col-sm-12">

                <fieldset>
                  <legend>Back Office</legend>
                  <div class="row">

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Back Office Welcome Page Image (1800 x 1200):</label>
                        <single-image-uploader
                          v-model="formData.back_office_background_image"
                        ></single-image-uploader>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Back Office Side Bar Image (355 x 596):</label>
                        <single-image-uploader
                          v-model="formData.back_office_sidebar_image"
                        ></single-image-uploader>
                      </div>
                    </div>

                  </div>

                </fieldset>

                <fieldset>
                  <legend>Client Portal</legend>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Client Welcome Page Image (1800 x 1200):</label>
                        <single-image-uploader v-model="formData.client_app_background_image"></single-image-uploader>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Client Side Bar Image (355 x 596):</label>
                        <single-image-uploader v-model="formData.client_app_sidebar_image"></single-image-uploader>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
          </div>
        </card>
      </ValidationObserver>
    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option} from 'element-ui';
import {Switch as LSwitch} from 'src/components/index'
import {SingleImageUploader} from 'src/components/index'


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    LSwitch,
    SingleImageUploader
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        back_office_background_image: "",
        back_office_sidebar_image: "",
        client_app_background_image: "",
        client_app_sidebar_image: ""
      },

    };
  },

  created() {

    this.loader = this.$loading.show({
      container: this.$refs.imagesForm
    });

    this.axios.get("/portal-images/builder").then((response) => {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = response.data[key];
      })
      this.loader.hide();
    }).catch((error) => {
      console.error(error);
    });

  },

  methods: {

    submit() {
      this.submitting = true;
      this.axios.post("portal-images/update", this.formData)
        .then((response) => {
          this.$notify({
            message: "Images Updated Successfully",
            timeout: 1000,
            type: 'success'
          })
        })
        .finally(() => {
          this.submitting = false;
        });
    },

  }
}
</script>

<style>
</style>
